import $ from 'jquery';

export default class stickyMenu {
  constructor() {
     this.menu = $('.header');
      this.menuHeight = this.menu.outerHeight();
      this.menuOffset = this.menu.offset().top;
      this.menuOffsetBottom = this.menuOffset + this.menuHeight;
      this.initStickyMenu();
  }

  // Methods
  initStickyMenu() {
    var that = this;

    $(window).scroll(function() {
      if ($(window).scrollTop() > that.menuOffsetBottom) {
        that.menu.addClass('header--sticky');
      } else {
        that.menu.removeClass('header--sticky');
      }
    });
  }
}



