import '../tailwind.css' 

// Menu
import MENU from './modules/menu';
const menu = new MENU();

// Sticky Menu
import STICKYMENU from './modules/sticky-menu';
const stickyMenu = new STICKYMENU();

// Slider
import SLIDER from './modules/sliders';
const slider = new SLIDER();

// AOS
import AOS from './modules/aos';
const aos = new AOS();

// Same Height
import SAMEHEIGHT from './modules/height';
const sameHeight = new SAMEHEIGHT();