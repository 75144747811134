import $ from 'jquery';

export default class Menu {
  constructor() {
    this.menu = $('.nav-menu');
    this.menuToggle = $('.header__menu__toggler');
    this.searchIcon = $('.header__search__button');
    this.body = $('body');
    this.events();
  }

  events() {
    this.menuToggle.on('click', this.toggleMenu.bind(this));
    this.searchIcon.on('click', this.toggleMenu.bind(this));
    $(document).on('keydown', this.onKeyDown.bind(this));
    $(document).on('keydown', (e) => {
      console.log(e);
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 83) {
        e.preventDefault();
        this.toggleMenu();
      }
    });
  }

  toggleMenu() {
    this.menu.toggleClass('open');
    this.menuToggle.toggleClass('open');
    this.body.toggleClass('no-scroll');
  }

  onKeyDown(event) {
    if (event.keyCode === 27) {
      this.menu.removeClass('open');
      this.menuToggle.removeClass('open');
      this.body.removeClass('no-scroll');
    }
  }
}
